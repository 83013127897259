<template>
  <b-sidebar id="nav-menu" right shadow no-header backdrop>
    <SelectLanguage/>
    <!-- <button @click="csvDown">landing csv down</button> -->
    <!-- <h2 class="icon icon-lab">Sidebar</h2> -->
    <ul class="navbar-nav">
      <li class="nav-item active" v-if="currentPage!=='home'">
        <a class="nav-link" v-if="isLanding" :href="logoURL"><i class="fas fa-home ml-2 mr-2"></i> {{$t('menu.home')}}</a>
        <router-link class="nav-link" v-else :to="logoURL"><i class="fas fa-home ml-2 mr-2"></i> {{$t('menu.home')}}</router-link>
      </li>

      <li class="nav-item" v-for="(category, inx) in categoryArray" :key="inx">
        <a class="nav-link navbar-link" v-b-toggle="`itemLink-${category.code}`" >
          <img :src='`${imageDomain}/assets/img/${categoryType[category.code]}.png`' :alt="`category-${categoryType[category.code]}`" class='ml-2 mr-2 categoryIcon'  width="30" height="30"/>{{getCategoryName(category.code, lang)}}
        </a>
        <b-collapse :id="`itemLink-${category.code}`" is-nav>
          <b-navbar-nav class="ml-auto" v-if="menuItemList">
            <li class="list-group-item" v-for="(dest, index) in menuItemList[category.code]" :key="index">
              <a class="nav-link" :href="dest.url"><country-flag :country='getISOCode(dest.country)'/>
              <span style="vertical-align: super; margin-right:10px;">{{dest.name}}</span> </a>
            </li>
          </b-navbar-nav>
        </b-collapse>
      </li>
    </ul>
  </b-sidebar>
</template>

<script>
import { BSidebar, BCollapse, VBToggle, BNavbarNav } from 'bootstrap-vue';
import CountryFlag from 'vue-country-flag';
import { mapGetters } from 'vuex';
import gMixin from '@/utils/mixins';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'NavMenu',
  mixins: [gMixin, imageUrlMixin],
  components: {
    BSidebar,
    BCollapse,
    BNavbarNav,
    SelectLanguage: () => import('@/components/atoms/SelectLanguage'),
    CountryFlag,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      categoryType: {
        FO: 'Flight_Only',
        CD: 'Company_Details',
        FH: 'vacation_pack',
        OT: 'Organize_tour_packages',
        SP: 'Sport_Pack',
      },
      menuItemList: [],
    };
  },
  watch: {
    listDestinations() {
      this.menuItemList = this.dealTypeListByCategory;
    },
  },
  computed: {
    ...mapGetters({
      currentPage: 'GET_CURRENT_PAGE',
      homeLink: 'GET_HOME_LINK',
      lang: 'GET_LANGUAGE',
      listDestinations: 'GET_LANDING_DESTINATIONS',
      categories: 'GET_CATEGORIES',
      isLanding: 'GET_IS_LANDING_PAGE',
      footerPagesList: 'GET_FOOTER_PAGES_LIST',
      footerCategoryList: 'GET_FOOTER_CATEGORY_LIST',
      marketerId: 'GET_MARKETER_ID',
    }),
    dealTypeListByCategory() {
      const { footerPagesList, footerCategoryList, listDestinations } = this;
      if (!footerPagesList || !footerCategoryList || !listDestinations) return null;
      // sort the category list
      footerCategoryList.sort((a, b) => (a.sort < b.sort ? -1 : 1));

      const pageList = Object.entries(footerPagesList).filter((item) => (item[1].active && item[0] !== 'AgreementApp') && this.filterAvailableDest(item[1])).map((item) => {
        if (item[1].link) {
          return [item[0], { ...item[1], ...Object.fromEntries(new URLSearchParams(item[1].link)) }];
        }
        return item;
      });
      const listByCategory = [];

      footerCategoryList.forEach((cat) => {
        listByCategory[cat.code] = [];
        const itemList = pageList.filter((page) => (page[1].category === cat.code));
        itemList.sort((a, b) => (a[1].sort > b[1].sort ? -1 : 1));
        listByCategory[cat.code] = itemList.map((item) => ({
          url: this.getLink(item),
          name: item[1].title,
          country: this.getCountryName(item[1].destination),
        }));
      });

      return listByCategory;
    },
    categoryArray() {
      // return Object.keys(this.dealTypeListByCategory);
      const { footerCategoryList } = this;
      if (!footerCategoryList) return null;
      return footerCategoryList;
    },
    logoURL() {
      return this.homeLink;
    },
    csvContent() {
      const { categoryArray, dealTypeListByCategory } = this;
      if (!categoryArray) return null;
      const rows = [];
      categoryArray.forEach((category) => {
        if (dealTypeListByCategory[category.code]) {
          dealTypeListByCategory[category.code].forEach((item) => {
            rows.push([`<url><loc>https://www.flying.co.il${item.url}</loc><lastmod>2024-06-01</lastmod><changefreq>daily</changefreq><priority>0.1</priority></url>`]);
          });
        }
      });
      return `data:text/csv;charset=utf-8, ${rows.map((e) => e.join(',')).join('\n')}`;
    },
  },
  mounted() {
    this.menuItemList = this.dealTypeListByCategory;
  },
  methods: {
    filterAvailableDest(it) {
      const { link } = it;
      const { listDestinations } = this;
      if (!link || link.startsWith('http')) return true;
      const info = Object.fromEntries(new URLSearchParams(link));
      // if (info.areaType) {
      //   return true;
      // }
      return listDestinations[info.dealType?.trim() || '']?.findIndex((item) => item.code === info.destination) > -1;
    },
    getCountryName(destCode) {
      const { listDestinations } = this;
      if (!listDestinations) return '';
      const types = Object.keys(listDestinations);

      let country = '';
      if (destCode && destCode?.length > 3) {
        country = destCode;
      }
      types.forEach((key) => {
        if (country === '') { country = listDestinations[key].find((dest) => (dest.code === destCode))?.countryName.en || ''; }
      });
      return country;
    },
    getCategoryName(code, lang) {
      const category = this.footerCategoryList.find((cat) => (cat.code === code));
      if (!category) return '';

      switch (lang) {
        case 'en':
          return category.nameTranslationEng;
        case 'ar':
          return category.nameTranslationAra;
        case 'ru':
          return category.nameTranslationRus;
        default:
          return category.nameTranslationHeb;
      }
    },
    csvDown() {
      const encode = encodeURI(this.csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encode);
      link.setAttribute('download', 'landing.csv');
      document.body.appendChild(link);

      link.click();
    },
    getISOCode(pCountryName) {
      const code = this.convertCountryNameToISOCode(pCountryName.toLowerCase());
      return code.toLowerCase();
    },
    getLink(item) {
      if (item[1].link) {
        if (item[1].link.startsWith('http')) {
          return item[1].link;
        } else {
          return `/${item[1].link}`;
        }
      }
      return `/${item[1].slug}`;
    },
  },
};
</script>
<style scoped>
  #nav-menu ul li a {
    display: block;
    padding: 1em 1em 1em 1.2em;
    outline: none;
    border-bottom: 1px dashed rgb(158 156 156 / 20%);
    text-transform: uppercase;
    text-shadow: 0 0 1px rgba(255,255,255,0.1);
    letter-spacing: 1px;
    font-weight: 400;
    -webkit-transition: background 0.3s, box-shadow 0.3s;
    transition: background 0.3s, box-shadow 0.3s;
  }
  #nav-menu ul li .nav-link {
    padding-right: 1em;
    padding-left: 1em;
    align-items: center;
    direction: rtl;
    /* background-color: white; */
  }
  .navbar-nav{
    padding-right: 0;
  }
  .navbar-link.collapsed:after {
    content: "\f107";
  }
  .navbar-link:after {
    content: "\f106";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    float: left;
  }
  .categoryIcon {
    width: 30px;
  }
  .fa-home:before {
    color: grey;
    font-size: 20px;
  }
  .list-group-item{
    background: transparent;
  }
</style>
<style>
 #nav-menu{
   height: 100vh;
    background-image: url('https://ecdn.speedsize.com/05b4c650-ae31-47ac-b679-5cd058f0cc68/https://www.flying.co.il/assets/img/banner_landing.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
 }
 .navbar-nav .list-group-item .flag {
  transform: scale(.4);
  -ms-transform: scale(.4);
  -webkit-transform: scale(.4);
  -moz-transform: scale(.4);
  margin-left: -12px;
  -ms-box-shadow: 1px 1px 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 1px 1px 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-box-shadow: 1px 1px 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 1px 1px 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
 }
 @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .b-sidebar-outer{
    z-index: 1050;
  }
  .b-sidebar > .b-sidebar-body {
    background-color: white;
  }
  .b-sidebar-backdrop {
    position: absolute;
  }
}
</style>
